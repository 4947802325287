<template>
  <div>
    <NavigationBar
      titel="Auslagen"
      isPreview
      :actions="actions"
    ></NavigationBar>
    <TippCard hintID="Verfuegbarkeit_Abwesenheiten_1_V1" text="Text">
    </TippCard>
    <section>
      <template>
        <v-container>
          <expenses-statistics-overview> </expenses-statistics-overview>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-receipt-text" title="Auslagen">
                <template v-slot:actions>
                  <v-btn-toggle v-model="helpers.displayMode" dense mandatory>
                    <v-btn depressed outlined icon value="table">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-table</v-icon
                      >
                    </v-btn>
                    <v-btn outlined icon value="kanban">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-view-column-outline</v-icon
                      >
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </Subheader>
              <div v-if="helpers.displayMode === 'table'" class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="table"
                ></v-skeleton-loader>
                <expenses-data-table
                  v-else
                  :expenses="expensesOutput"
                ></expenses-data-table>
              </div>
              <div v-else class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="calendar"
                ></v-skeleton-loader>
                <!-- <expenses-calendar
                  v-else
                  :expenses="expensesOutput"
                ></expenses-calendar> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
// import { auth } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import ExpensesDataTable from "@/components/finance/expenses/ExpensesDataTable.vue";
// import ExpensesStatisticsOverview from "@/components/finance/expenses/ExpensesStatisticsOverview.vue";
import {
  AVAILABILITY_expenses_create,
  AVAILABILITY_expenses_read_all,
} from "@/data/permission-types.js";
// import { FINANCE } from "@/store/modules.js";
// import { GET_expenses } from "@/store/action-types.js";

export default {
  name: "finance-expenses",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
    ExpensesDataTable,
    // ExpensesStatisticsOverview,
  },
  data() {
    return {
      search: "",
      actions: [
        {
          title: "Erstellen",
          permission: `${AVAILABILITY_expenses_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({ name: "finance-expenses-new" });
          },
        },
      ],
      helpers: {
        displayMode: "table",
      },
      expensesPersonFilter: "own",
      expensesStateFilter: "active",
    };
  },
  computed: {
    expensesOutput() {
      switch (this.expensesStateFilter) {
        case "all":
          return this.allexpenses;
        case "active":
          return this.activeexpenses;
        case "upcoming":
          return this.upcomingexpenses;
        case "finished":
          return this.finishedexpenses;
        default:
          return [];
      }
    },
    expenses() {
      return [];
      // const expenses = this.$store.state.finance.expenses;
      // return expenses.map((Expense) => {
      //   // status is active if duration.start.timestamp is now or in the past and duration.end.timestamp is in the future
      //   // status is upcoming if duration.start.timestamp is in the future
      //   // status is finished if duration.end.timestamp is in the past

      //   const getexpensestatus = (start, end) => {
      //     const now = new Date().getTime();
      //     if (start > now) {
      //       return "upcoming";
      //     } else if (end < now) {
      //       return "finished";
      //     } else {
      //       return "active";
      //     }
      //   };
      //   return {
      //     ...Expense,
      //     status: getexpensestatus(
      //       Expense.duration.start.timestamp,
      //       Expense.duration.end.timestamp
      //     ),
      //   };
      // });
    },
    allexpenses() {
      return this.expenses;
    },
    activeexpenses() {
      const filtered = this.expenses.filter(function (obj) {
        return obj.status == "active";
      });
      return filtered;
    },
    upcomingexpenses() {
      const filtered = this.expenses.filter(function (obj) {
        return obj.status == "upcoming";
      });
      return filtered;
    },
    finishedexpenses() {
      const filtered = this.expenses.filter(function (obj) {
        return obj.status == "finished";
      });
      return filtered;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "expenses", data: this.expenses }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      var allowedToReadAllexpenses = this.$store.getters[
        "organization/checkPermissionByID"
      ](`${AVAILABILITY_expenses_read_all}`);
      if (allowedToReadAllexpenses) {
        // this.$store.dispatch(`${AVAILABILITY}${GET_expenses}`, {
        //   organizationId: this.$route.params.organizationId,
        //   queryIsFiltered: true,
        //   query: {
        //     type: "all",
        //   },
        //   uid: auth.currentUser.uid,
        // });
      } else {
        // this.$store.dispatch(`${AVAILABILITY}${GET_expenses}`, {
        //   organizationId: this.$route.params.organizationId,
        //   queryIsFiltered: true,
        //   query: {
        //     type: "all",
        //     filterByUid: true,
        //   },
        // });
      }
    },
  },
};
</script>
