<template>
  <div>
    <v-data-table
      :headers="headersOutput"
      :items="expenses"
      item-key="meta.id"
      multi-sort
      sort-by="duration.start.firebaseFormat"
      :sort-desc="true"
      :items-per-page="15"
      :footer-props="footerProps"
      :search="search"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-subtitle>Filter</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <v-select
                  outlined
                  label="Status"
                  dense
                  multiple
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  outlined
                  label="Kategorie"
                  multiple
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  outlined
                  label="Nächste Aktivität"
                  multiple
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>

        <v-card flat>
          <v-card-subtitle>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Suchen"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="groupBy"
                  outlined
                  label="Gruppieren nach"
                  hide-details="auto"
                  dense
                ></v-select>
              </v-col>
              <v-col>
                <table-column-selector-menu
                  v-model="headersOutput"
                  :headers="headers"
                ></table-column-selector-menu>
                <v-btn depressed
                  ><v-icon left>mdi-export</v-icon>Auswahl exportieren</v-btn
                >
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
        <v-divider></v-divider>
      </template>

      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
          {{ stripPrefix(items[0].groupBy) }}
        </th>
      </template>
      <template v-slot:[`item.user.displayName`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.user.displayName }}</span
          >
          <!-- <small class="grey--text">{{ item.group.title }}</small> -->
          <small class="grey--text">Benutzergruppe</small>
        </div>
      </template>
      <!-- <template v-slot:[`item.progress`]="{ item }"> </template> -->
      <template #[`item.full_name`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.full_name }}</span
          >
          <small>{{ item.post }}</small>
        </div>
      </template>
      <!-- status -->
      <template #[`item.status`]="{ item }">
        <expense-status-chip :status="item.status" small> </expense-status-chip>
      </template>
      <template #[`item.reason.id`]="{ item }">
        <expense-reason-chip :reason="item.reason" small></expense-reason-chip>
      </template>
      <template v-slot:no-data>
        <div
          style="
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <p class="subtitle-1">Keine Auslagen vorhanden.</p>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ExpenseStatusChip from "@/components/finance/expenses/ExpenseStatusChip.vue";
import TableColumnSelectorMenu from "@/components/_system/helpers/TableColumnSelectorMenu.vue";
// import ExpenseReasonChip from "@/components/finance/expenses/ExpenseReasonChip.vue";
export default {
  name: "expenses-data-table",
  props: {
    expenses: {
      type: Array,
      required: true,
    },
  },
  components: {
    ExpenseStatusChip,
    TableColumnSelectorMenu,
    // ExpenseReasonChip,
  },
  data() {
    return {
      search: "",
      expensesPersonFilter: "own",
      expensesStateFilter: "active",
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
      headersOutput: [
        {
          sortKey: 1,
          text: "#",
          value: "internalNumber",
          group: "",
          default: true,
        },
        {
          sortKey: 2,
          text: "Status",
          value: "status",
          group: "",
          default: true,
        },
        {
          sortKey: 3,
          text: "Name",
          value: "user.displayName",
          group: "",
          default: true,
        },
        {
          sortKey: 4,
          text: "Kategorie",
          value: "category",
          group: "",
          default: true,
        },
        { sortKey: 6, text: "Summe", value: "total", group: "", default: true },
        {
          sortKey: 7,
          text: "Erstellt am",
          value: "x",
          group: "",
          default: true,
        },
        {
          sortKey: 12,
          text: "Nächste Aktivität",
          value: "progress",
          group: "",
          default: true,
        },
      ],
      headers: [
        {
          sortKey: 1,
          text: "#",
          value: "internalNumber",
          group: "",
          default: true,
        },
        {
          sortKey: 2,
          text: "Status",
          value: "status",
          group: "",
          default: true,
        },
        {
          sortKey: 3,
          text: "Name",
          value: "user.displayName",
          group: "",
          default: true,
        },
        {
          sortKey: 4,
          text: "Kategorie",
          value: "category",
          group: "",
          default: true,
        },
        {
          sortKey: 5,
          text: "Kostenstelle",
          value: "journal",
          group: "",
          default: false,
        },
        { sortKey: 7, text: "Summe", value: "total", group: "", default: true },
        {
          sortKey: 8,
          text: "Erstellt am",
          value: "x",
          group: "",
          default: true,
        },
        {
          sortKey: 9,
          text: "Eingereicht am",
          value: "y",
          group: "",
          default: false,
        },
        {
          sortKey: 10,
          text: "Geprüft am",
          value: "z",
          group: "",
          default: false,
        },
        {
          sortKey: 11,
          text: "Bezahlt am",
          value: "xx",
          group: "",
          default: false,
        },
        {
          sortKey: 12,
          text: "Nächste Aktivität",
          value: "progress",
          group: "",
          default: true,
        },
      ],
    };
  },
  methods: {
    routeDetails(item) {
      this.$router.push({
        name: "finance-expenses-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    reasonText(reasonId) {
      switch (reasonId) {
        case "0":
          return "Keine Angabe";
        case "1":
          return "Verpflichtungen/Verbindlichkeiten/Termine";
        case "2":
          return "Nicht in Stadt";
        case "3":
          return "Keine Diensttauglichkeit";
        case "4":
          return "Eingeschränkte Diensttauglichkeit";
        case "8":
          return "Beurlaubung";
        case "9":
          return "Sonstiges";
        default:
          return "Fehler";
      }
    },
  },
};
</script>
